import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
} from '@mui/material';
import {Link, WithIRI} from '../../types/api';
import linkIcons from '../../lib/linkIcons';
import React, {ReactNode} from 'react';
import {getBestTranslatedValue} from '../../lib/localeHelper';
import {addSourceTracker, TrackingSource} from '../../lib/tracking';

export type LinkProp = {
    label: ReactNode;
    trackingUrl?: string | undefined;
    url?: string | undefined;
    locale?: string | undefined;
} & Omit<WithIRI<Link>, 'label' | 'url' | 'id'>;

const returnFalse = () => false;

type Props = {
    links: LinkProp[];
    locale: string | undefined;
    isPreview?: boolean;
};

export default function Links({links, locale, isPreview}: Props) {
    return (
        <List
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
            }}
        >
            {links.map((l, index) => {
                const label = getBestTranslatedValue(
                    l.translations ?? {},
                    'label',
                    l.label,
                    l.locale || locale,
                );
                const url = isPreview
                    ? l.url
                    : l.trackingUrl
                      ? addSourceTracker(l.trackingUrl, TrackingSource.Profile)
                      : l.url;

                return (
                    <ListItem disablePadding key={index}>
                        <ListItemButton
                            disabled={!url}
                            component={'a'}
                            href={url || '#'}
                            onClick={!url ? returnFalse : undefined}
                            target={'_blank'}
                            rel={'noopener'}
                        >
                            <ListItemIcon>
                                <SvgIcon
                                    component={
                                        linkIcons[l.icon || 'link'].image
                                    }
                                    inheritViewBox
                                    color={'primary'}
                                />
                            </ListItemIcon>
                            <ListItemText primary={label || l.url} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
