type Data = Record<string, any>;

export function clearAssociationIds<T extends Data>(data: T, level = 0): T {
    const newData: T = {} as T;

    if (
        level === 0 &&
        Object.prototype.hasOwnProperty.call(data, 'logo') &&
        data.logo &&
        data.logo['@id']
    ) {
        data['logo' as keyof T] = data.logo['@id'];
        delete data.logo;
    }
    if (
        level === 0 &&
        Object.prototype.hasOwnProperty.call(data, 'cover') &&
        data.cover &&
        data.cover['@id']
    ) {
        data['cover' as keyof T] = data.cover['@id'];
        delete data.cover;
    }
    Object.keys(data)
        .filter(k => level === 0 || k !== '@id')
        .forEach((k: keyof T) => {
            if (k === 'id' && data['@id']) {
                newData[k] = data['@id'];
                return;
            }
            const d = data[k];

            if (d && typeof d === 'object') {
                newData[k] = clearAssociationIds<typeof d>(d, level + 1);
            } else {
                newData[k] = d;
            }
        });

    return newData;
}
