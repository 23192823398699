import {EntityWithId, getCache} from '../services/api/CRUD';

type Resolve<T> = (value: T | PromiseLike<T>) => void;

type PromiseResolveRef<T extends EntityWithId> = {
    promise?: Promise<T>;
    resolve?: Resolve<T>;
    reject?: (reason?: any) => void;
};

export type RequestListThrottle<T extends EntityWithId> = {
    lock: Promise<void>;
    stack: Record<string, PromiseResolveRef<T>>;
    timeout: ReturnType<typeof setTimeout> | null;
};

function createThrottle<T extends EntityWithId>(): RequestListThrottle<T> {
    return {
        lock: Promise.resolve(),
        stack: {},
        timeout: null,
    };
}

const throttles: Record<string, RequestListThrottle<any>> = {};

export async function getThrottle<T extends EntityWithId>(
    entity: string,
    id: string,
    loader: (ids: string) => Promise<T[]>,
): Promise<T> {
    if (!throttles[entity]) {
        throttles[entity] = createThrottle<T>();
    }

    await throttles[entity].lock;
    const listThrottle: RequestListThrottle<T> = throttles[entity];

    const cache = getCache(entity);
    const cached = cache.get(id);
    if (cached) {
        return cached;
    }

    if (listThrottle.stack[id]) {
        return listThrottle.stack[id].promise!;
    }

    const r: PromiseResolveRef<T> = {};
    const promise: Promise<T> = new Promise((resolve, reject) => {
        r.resolve = resolve;
        r.reject = reject;
    });
    r.promise = promise;

    listThrottle.stack[id] = r;

    const flush = () => {
        const t: RequestListThrottle<T> = throttles[entity];
        let resolveLock: Resolve<void>;
        t.lock = new Promise(resolve => (resolveLock = resolve));

        const stack = {...t.stack};
        throttles[entity] = {
            lock: Promise.resolve(),
            stack: {},
            timeout: null,
        };

        resolveLock!();

        const ids = Object.keys(stack);

        loader(ids.join(','))
            .then(items => {
                items.forEach(k => {
                    stack[k.id].resolve!(k);
                    delete stack[k.id];
                });

                Object.entries(stack).forEach(([, {reject}]) =>
                    reject!(new Error('NRK')),
                );
            })
            .catch(e => {
                Object.entries(stack).forEach(([, {reject}]) => reject!(e));
            });
    };

    if (listThrottle.timeout) {
        clearTimeout(listThrottle.timeout);
    }

    if (Object.keys(listThrottle.stack).length >= 20) {
        flush();
    } else {
        listThrottle.timeout = setTimeout(flush, 0);
    }

    return promise;
}
