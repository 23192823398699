import {SignatureAssignment, SignatureModel, Team} from '../../types/api';
import {
    apiDelete,
    createListResponse,
    customList,
    get,
    list,
    ListResponse,
    post,
    PostFn,
    put,
    RequestOptions,
} from './CRUD';
import {RecursivePartial} from '../../types/list';
import {clearAssociationIds} from './clearAssociation';
import {AxiosRequestConfig} from 'axios';
import Api from '../../lib/apiClient';
import {profileEntity} from './ProfileApi';
import {clearCachesByTag} from '../../lib/cache';

export const signatureModelEntity = 'signature-models';
export const profileSignatureCacheTag = 'profile-signatures';

export const listSignatureModels = (options?: RequestOptions) => {
    return list<SignatureModel>(signatureModelEntity, {
        ...options,
        params: {
            ...(options?.params || {}),
            'order[title]': 'asc',
        },
    });
};

export const listProfileSignatureModels = async (
    profileId: string,
): Promise<ListResponse<SignatureModel>> => {
    return createListResponse(
        await customList<any>(`${profileEntity}/${profileId}/signatures`, [
            profileSignatureCacheTag,
        ]),
    );
};

export const getSignatureModel = (id: string) =>
    get<SignatureModel>(signatureModelEntity, id);

export const getSignatureModelAssignment = async (
    id: string,
    options: AxiosRequestConfig<SignatureAssignment> = {},
) => {
    return (await Api.get(`/${signatureModelEntity}/${id}/assignment`, options))
        .data as SignatureAssignment;
};

export const putSignatureModelAssignment = async (
    id: string,
    data: SignatureAssignment,
) => {
    clearCachesByTag(profileSignatureCacheTag);

    return (await Api.put(`/${signatureModelEntity}/${id}/assignment`, data))
        .data as SignatureAssignment;
};

export const postSignatureModel: PostFn<
    SignatureModel,
    RecursivePartial<SignatureModel>
> = data => {
    clearCachesByTag(profileSignatureCacheTag);

    return post(signatureModelEntity, data);
};

export const putSignatureModel = (
    id: string,
    data: RecursivePartial<SignatureModel | SignatureAssignment>,
) => {
    return put<SignatureModel>(
        signatureModelEntity,
        id,
        clearAssociationIds(data),
    );
};

export const deleteSignatureModel = (id: string) => {
    clearCachesByTag(profileSignatureCacheTag);

    return apiDelete<Team>(signatureModelEntity, id);
};
